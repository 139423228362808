<template>
  <div class="StepFlowView">
    <StepBackground :image="step.image" />

    <transition
      :name="transitionName"
      :mode="transitionMode">
      <router-view
        :key="activityName"
        class="view"
        @progress="progress => onActivityProgress(progress)" />
    </transition>

    <transition name="slideOut">
      <div
        v-if="!hideNavigationHeader"
        class="header">
        <MainBackButton @request-exit="exit()" />
      </div>
    </transition>
    <transition name="slideOut">
      <keep-alive>
        <StepFlowFooter
          v-if="!hideNavigationFooter"
          v-bind="{ courseId, stepId, memoId, activityName, activityProgress }"
          class="StepFlowFooter" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import loadImage from 'image-promise';
import store from '@/store';
import StepBackground from '@/modules/course/components/StepBackground';
import ThemeMixin from '../../theme-mixin';

import StepFlowFooter from './StepFlowFooter';
import MainBackButton from '../../components/MainBackButton';
import { EventBus } from '@/event-bus';

export default {
    components: { StepBackground, StepFlowFooter, MainBackButton },
    mixins: [ThemeMixin],
    props: {
        courseId: {
            type: String,
            required: true
        },
        stepId: {
            type: String,
            required: true
        },
        memoId: {},
        activityName: {
            type: String
        },
        gameId: {
            type: String
        },
        ready: {}
    },
    data() {
        return {
            transitionMode: '',
            transitionName: '',
            activityProgress: 0, // For read activity
            transitionDirection: 0,
            transitionType: ''
        };
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        xxx() {
            return {
                activityName: this.activityName,
                transition: this.$route.name === 'Course-ActivityTransition',
                showingGame: !!this.gameId || this.$route.name === 'GameView'
            };
        },
        hideNavigationHeader() {
            return this.xxx.showingGame || this.$route.name === 'Course-QuizView-Play';
        },
        hideNavigationFooter() {
            const onlyHasOneActivity = this.step.activityNames.length === 1;
            if (onlyHasOneActivity && this.step.activityNames[0] === 'memorize2') {
                return false; // Because we need multimemo navigation
            }
            return (
                onlyHasOneActivity ||
                this.xxx.showingGame ||
                ['Course-QuizView-Play', 'Course-StepEnd', 'Course-QuizView'].indexOf(this.$route.name) !== -1
            );
        }
    },
    watch: {
        xxx(to, from) {
            this.transitionMode = 'out-in';
            if (to && from) {
                const toIndex = this.step.activityNames.indexOf(to.activityName);
                const fromIndex = this.step.activityNames.indexOf(from.activityName);
                if (!to.activityName) {
                    this.transitionDirection = 1;
                    this.transitionName = 'NORMAL-left';
                } else if (toIndex > fromIndex) {
                    this.transitionDirection = 1;
                    this.transitionName = 'NORMAL-left';
                } else if (toIndex < fromIndex) {
                    this.transitionDirection = -1;
                    this.transitionName = 'NORMAL-right';
                } else {
                    if (!from.showingGame && to.showingGame) {
                        this.transitionName = 'slideUp';
                    } else if (from.showingGame && !to.showingGame) {
                        this.transitionName = 'slideDown';
                    } else {
                        this.transitionName = 'NORMAL-' + (this.transitionDirection === -1 ? 'right' : 'left');
                    }
                }
            } else {
                this.transitionName = '';
            }
        }
    },
    mounted() {
        EventBus.$on('stepFlowNext', this.exit);
    },
    beforeDestroy() {
        EventBus.$off('stepFlowNext', this.exit);
    },
    methods: {
        onActivityProgress(progress) {
            this.activityProgress = progress;
        },
        exit() {
            const course = this.$store.state.moduleCourse.courses[this.courseId];
            if (course.lifeskillNumber) {
                this.$router.push({
                    name: 'DashboardLifeskillViewWithTab',
                    params: { lifeskillId: course.lifeskillNumber, tabName: 'theory' }
                });
            } else {
                this.$router.push({
                    name: 'CourseHomeView',
                    params: { courseId: this.courseId }
                });
            }
        }
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch('moduleCourse/loadCourseFull', { courseId: to.params.courseId });

        const step = store.state.moduleCourse.steps[to.params.stepId];
        try {
            await loadImage(step.image);
        } catch {
            //
        }

        next(vm => {
            // vm.updateTheme()
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('moduleCourse/setLastStepId', { stepId: this.stepId });
        next();
    }
};
</script>

<style lang="scss" scoped>
$headerHeight: 3em;
$footerHeight: 4em;

.StepFlowView {
    display: flex;
    flex-direction: column;
}
.body {
    position: relative;
    flex: 1;
}
.header,
.StepFlowFooter {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
}
.header {
    top: 0;
    right: auto;
}
.MainBackButton {
    margin-top: 0.5em;
    margin-left: 0.5em;
}
.StepFlowFooter {
    bottom: 0;
    height: $footerHeight;
}

.view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: $footerHeight;
    left: 0;

    &.QuizView,
    &.GameView,
    &.ReadView,
    &.StepEndView {
        top: 0;
        bottom: 0;
    }

    // ------------------------------
    $transitionDuration: 0.3s;
    $distance: 5em;
    $transitionScale: 0.9;

    &.NORMAL-right-enter-active,
    &.NORMAL-right-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.NORMAL-right-enter {
        opacity: 0;
        transform: translate3d(-$distance, 0, 0);
    }
    &.NORMAL-right-leave-to {
        transform: translate3d($distance, 0, 0);
        opacity: 0;
    }

    &.NORMAL-left-enter-active,
    &.NORMAL-left-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.NORMAL-left-enter {
        transform: translate3d($distance, 0, 0);
        opacity: 0;
    }
    &.NORMAL-left-leave-to {
        opacity: 0;
        transform: translate3d(-$distance, 0, 0);
    }

    &.ActivityTransitionView {
        &.NORMAL-right-enter-active,
        &.NORMAL-left-enter-active {
            // transition: transform 2s ease-out, opacity 1s;
        }

        &.NORMAL-right-enter,
        &.NORMAL-right-leave-to,
        &.NORMAL-left-enter,
        &.NORMAL-left-leave-to {
            transform: scale($transitionScale);
        }
    }

    // -----------------------------------
    $distance: 2em;
    $transitionDuration: 0.5s;
    &.slideUp-enter-active,
    &.slideUp-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideUp-enter {
        transform: translate3d(0, $distance, 0);
        opacity: 0;

        &.ActivityTransitionView {
            transform: scale(0.5);
        }
    }
    &.slideUp-enter-to {
        transform: translate3d(0%, 0, 0);
    }
    &.slideUp-leave-to {
        transform: translate3d(0, -$distance, 0);
        opacity: 0;

        &.ActivityTransitionView {
            transform: translate3d(0, -1em, 0);
        }
    }

    &.slideDown-enter-active,
    &.slideDown-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideDown-enter {
        transform: translate3d(0, -$distance, 0);
        opacity: 0;
    }
    &.slideDown-enter-to {
        transform: translate3d(0%, 0, 0);
    }
    &.slideDown-leave-to {
        transform: translate3d(0, $distance, 0);
        opacity: 0;
    }
}

.header {
    color: white;

    &.slideOut-enter-active,
    &.slideOut-leave-active {
        transition: transform 0.5s ease-out;
    }
    &.slideOut-enter,
    &.slideOut-leave-to {
        transform: translate3d(0, -100%, 0);
    }
}

.StepFlowFooter {
    &.slideOut-enter-active,
    &.slideOut-leave-active {
        transition: transform 0.5s ease-out;
    }
    &.slideOut-enter,
    &.slideOut-leave-to {
        transform: translate3d(0, 100%, 0);
    }
}

@include media('<mobile') {
    $footerHeightMobile: 3em;
    .StepFlowFooter {
        height: $footerHeightMobile;
    }
    .view {
        bottom: $footerHeightMobile;
    }
}
</style>

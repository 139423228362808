<template>
  <div class="StepFlowFooter">
    <ActivityTab
      v-for="(tab, i) of tabs"
      :key="i"
      v-bind="tab"
      :theme-color="theme.color"
      :previous-progress="previousStateForActivities[tab.activityName]"
      @click="selectTab(i)"
      @pagination="index => navigateMultimemo(index)" />
  </div>
</template>

<translations>
  tabLabels:
    read: 'Read'
    read_no: 'Les'
    memorize: 'Memo'
    quiz: 'Quiz'
    # memorize2: 'Details'
    # memorize2_no: 'Detaljer'
    memorize2: 'Memo'
    quiz2: 'Quiz'
    # overview: 'Overview'
    # overview_no: 'Oversikt'
    overview: 'Memo'
</translations>

<script>
import Color from 'color';
import ActivityTab from '@/modules/course/components/ActivityTab';
import { EventBus } from '@/event-bus';

export default {
    components: { ActivityTab },
    props: {
        courseId: {
            type: String,
            required: true
        },
        stepId: {
            type: String,
            required: true
        },
        memoId: {},
        activityName: {
            type: String
        },
        activityProgress: {
            type: Number,
            default: 0.5
        }
    },
    inject: ['theme'],
    data() {
        return {
            reachedLastMnemonicIndex: null,
            previousStateForActivities: {}
        };
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        themeColor() {
            return Color(this.theme.color).mix(Color('black'), 0.8).hex();
        },
        noActivityTransitions() {
            return this.$store.state.moduleApp.debugSettings.noActivityTransitions;
        },
        tabs() {
            function isMandatory(n) {
                return ['read', 'quiz', 'quiz2'].indexOf(n) !== -1;
            }
            let precedingMandatoryActivitiesCompleted = true;
            let precedingActivitiesCompleted = true;
            let didAssignPulsation = false;
            const flowWithOverviewAndDetail = this.step.memorize && this.step.memorize2;
            const activeIndex = this.step.activityNames.indexOf(this.activityName);

            return this.step.activityNames.map((activityName, i) => {
                const activity = this.step[activityName];
                const active = activityName === this.activityName;

                const pulsate =
                    !didAssignPulsation && activity.progress < 1 && activeIndex < i && precedingActivitiesCompleted;
                if (pulsate) {
                    didAssignPulsation = true;
                }

                const locked = !precedingMandatoryActivitiesCompleted;
                if (isMandatory(activityName) && activity.progress < 1) {
                    precedingMandatoryActivitiesCompleted = false;
                }
                if (activity.progress < 1) {
                    precedingActivitiesCompleted = false;
                }

                let pagination = null;
                if (activityName === 'memorize2') {
                    const memoIndex = activity.memoIds.indexOf(this.memoId);
                    const memoCount = activity.memoIds.length;
                    const onGamesScreen = memoIndex === -1;

                    pagination = {
                        count: memoCount + 1,
                        index: onGamesScreen ? memoCount : memoIndex,
                        onGamesScreen,
                        // pulsateIndex: onGamesScreen ? -1 : memoIndex + 1
                        pulsateIndex: this.reachedLastMnemonicIndex ? memoIndex + 1 : -1
                    };
                }
                return {
                    label:
                        activityName === 'memorize' && flowWithOverviewAndDetail
                            ? this.$t(`tabLabels.overview`)
                            : this.$t(`tabLabels.${activityName}`),
                    icon: this.iconMap[activityName],
                    progress: activity.progress,
                    locked,
                    activityProgress: active ? this.activityProgress : 0,
                    activityName,
                    active,
                    pulsate,
                    pagination
                };
            });
        }
    },
    created() {
        this.iconMap = {
            read: 'activities/read',
            memorize: 'activities/memo-overview',
            quiz: 'activities/quiz',
            memorize2: 'activities/memo-details',
            quiz2: 'activities/quiz'
        };
        this.step.activityNames.forEach((activityName, i) => {
            const activity = this.step[activityName];
            // this.previousStateForActivities[activityName] = activity.progress
            this.$set(this.previousStateForActivities, activityName, activity.progress);
        });

        EventBus.$on('mnemonicIndexChanged', this.onMnemonicIndexChanged);
    },
    beforeDestroy() {
        EventBus.$off('mnemonicIndexChanged', this.onMnemonicIndexChanged);
    },
    deactivated() {
        this.step.activityNames.forEach((activityName, i) => {
            const activity = this.step[activityName];
            // this.previousStateForActivities[activityName] = activity.progress
            this.$set(this.previousStateForActivities, activityName, activity.progress);
        });
    },
    methods: {
        onMnemonicIndexChanged({ isLast }) {
            this.reachedLastMnemonicIndex = isLast;
        },
        selectTab(tabIndex) {
            const tab = this.tabs[tabIndex];
            if (tab.locked || tab.active) {
                return;
            }
            this.$router.replace({
                name: this.noActivityTransitions ? 'Course-ActivityRedirect' : 'Course-ActivityTransition',
                params: {
                    activityName: tab.activityName
                }
            });
        },
        navigateMultimemo(index) {
            const activity = this.step[this.activityName];
            if (index < activity.memoIds.length) {
                this.$router.replace({
                    name: 'MemoView',
                    params: {
                        activityName: this.activityName,
                        memoId: activity.memoIds[index]
                    }
                });
            } else {
                this.$router.replace({
                    name: 'GamesView',
                    params: {
                        activityName: this.activityName
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.StepFlowFooter {
    bottom: 0;
    left: 0;
    right: 0;

    color: white;

    padding: 0 1em;
    display: flex;
    justify-content: center;
}

.ActivityTab {
    &:not(:first-child) {
        margin-left: 0.8em;
    }
}
</style>

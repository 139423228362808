var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ActivityTab",class:{ active: _vm.active, locked: _vm.locked, pulsate: _vm.pulsate, completed: _vm.progress === 1 }},[_c('div',{staticClass:"iconContainer --global-clickable",on:{"click":function($event){return _vm.$emit('click')}}},[_c('svgicon',{staticClass:"icon",attrs:{"icon":_vm.icon}}),(_vm.runCompletionAnim)?_c('AnimatedChekmark'):_vm._e()],1),_c('TransitionExpandCollapse',{attrs:{"horizontal":""}},[(_vm.active)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"labelContainer"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),(_vm.pagination)?_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"--global-clickable navButton navButton--prev",class:{
            disabled: _vm.atStart,
            pulsate: !_vm.atStart && _vm.pagination.pulsateIndex === _vm.pagination.index - 1
          },on:{"click":function($event){return _vm.$emit('pagination', _vm.pagination.index - 1)}}},[_c('svgicon',{attrs:{"icon":"chevron-down"}})],1),_c('div',{staticClass:"paginationText"},[(_vm.pagination.onGamesScreen)?_c('span',[_c('svgicon',{staticClass:"gameIcon",attrs:{"icon":"game-icon"}})],1):_c('span',[_vm._v(_vm._s(_vm.pagination.index + 1)+"/"+_vm._s(_vm.pagination.count - 1))])]),_c('div',{staticClass:"--global-clickable navButton navButton--next",class:{
            disabled: _vm.atEnd,
            pulsate: !_vm.atEnd && _vm.pagination.pulsateIndex === _vm.pagination.index + 1,
            nextIsGame: _vm.nextIsGame
          },on:{"click":function($event){return _vm.$emit('pagination', _vm.pagination.index + 1)}}},[(_vm.nextIsGame)?_c('svgicon',{attrs:{"icon":"game-icon"}}):_c('svgicon',{attrs:{"icon":"chevron-down"}})],1)]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
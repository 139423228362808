<template>
  <div
    class="ActivityTab"
    :class="{ active, locked, pulsate, completed: progress === 1 }">
    <div
      class="iconContainer --global-clickable"
      @click="$emit('click')">
      <svgicon
        class="icon"
        :icon="icon" />

      <AnimatedChekmark v-if="runCompletionAnim" />
    </div>

    <TransitionExpandCollapse horizontal>
      <div
        v-if="active"
        class="content">
        <div class="labelContainer">
          <div class="label">
            {{ label }}
          </div>
        </div>

        <div
          v-if="pagination"
          class="pagination">
          <div
            class="--global-clickable navButton navButton--prev"
            :class="{
              disabled: atStart,
              pulsate: !atStart && pagination.pulsateIndex === pagination.index - 1
            }"
            @click="$emit('pagination', pagination.index - 1)">
            <svgicon icon="chevron-down" />
          </div>
          <div class="paginationText">
            <span v-if="pagination.onGamesScreen"><svgicon
              class="gameIcon"
              icon="game-icon" /></span>
            <span v-else>{{ pagination.index + 1 }}/{{ pagination.count - 1 }}</span>
          </div>
          <div
            class="--global-clickable navButton navButton--next"
            :class="{
              disabled: atEnd,
              pulsate: !atEnd && pagination.pulsateIndex === pagination.index + 1,
              nextIsGame
            }"
            @click="$emit('pagination', pagination.index + 1)">
            <svgicon
              v-if="nextIsGame"
              icon="game-icon" />
            <svgicon
              v-else
              icon="chevron-down" />
          </div>
        </div>
      </div>
    </TransitionExpandCollapse>
  </div>
</template>

<script>
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse';
import AnimatedChekmark from './AnimatedChekmark';

export default {
    components: { TransitionExpandCollapse, AnimatedChekmark },
    props: {
        icon: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        locked: {
            type: Boolean,
            default: false
        },
        previousProgress: {
            type: Number,
            default: 0
        },
        progress: {
            type: Number,
            default: 0
        },
        pulsate: {
            type: Boolean,
            default: false
        },
        pagination: {
            type: Object,
            default: null
        },
        themeColor: {
            type: String,
            default: 'red'
        }
    },
    data() {
        return {
            runCompletionAnim: false
        };
    },
    computed: {
        atStart() {
            return this.pagination.index === 0;
        },
        atEnd() {
            return this.pagination.index === this.pagination.count - 1;
        },
        nextIsGame() {
            return this.pagination.index === this.pagination.count - 2;
        },
        justCompleted() {
            return this.progress === 1 && this.previousProgress < this.progress;
        }
    },
    watch: {
        justCompleted(yes) {
            if (yes) {
                setTimeout(() => {
                    this.runCompletionAnim = true;
                }, 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$backgroundColor: white;
$color: black;
$sizeLarge: 3em;
$sizeSmall: 2.5em;

.ActivityTab {
    position: relative;
    height: $sizeLarge;
    border-radius: $sizeLarge;
    background-color: $backgroundColor;
    transition: background-color 0.5s;

    display: flex;
    align-items: center;

    &.locked {
        opacity: 0.2;

        .iconContainer {
            cursor: default;
        }
    }
    &.active {
        border: 2px solid $completionColor;
        .iconContainer {
            cursor: default;
        }
    }
    &.completed {
        .icon {
            fill: $completionColor;
        }
        .label {
            color: $completionColor;
        }
    }

    &.pulsate {
        animation: bounce 2s;
        animation-iteration-count: 6; // infinite

        @keyframes bounce {
            0%,
            25%,
            50%,
            75%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-0.5em);
            }
            60% {
                transform: translateY(-0.2em);
            }
        }
    }
}
.iconContainer {
    position: relative;
    width: $sizeLarge;
    height: $sizeLarge;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        $offset: -0.4em;
        position: absolute;
        content: '';
        top: $offset;
        right: $offset;
        bottom: $offset;
        left: $offset;
        border-radius: 50%;
    }
}
.icon {
    $size: 50%;
    width: $size;
    height: $size;
    fill: $color;
    transition: fill 0.5s;
}
.CircularButton.pulse {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.AnimatedChekmark {
    $inset: 0.5em;
    position: absolute;
    top: $inset;
    right: $inset;
    bottom: $inset;
    left: $inset;
}
.content {
    display: flex;
    align-items: center;
}
.labelContainer {
    padding: 0 1em;
    padding-left: 0;
}
.label {
    font-weight: 600;
    color: $color;
}

.pagination {
    display: flex;
    align-items: center;
    margin-right: 1em;
    margin-left: -1em;
}
.paginationText {
    color: rgba($color, 0.7);
    padding: 0 0.3em;
}
.gameIcon {
    $size: 1.4em;
    display: block;
    fill: black;
    width: $size;
    height: $size;
}
.navButton {
    $size: 1.5em;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        $offset: -0.7em;
        display: block;
        content: '';
        position: absolute;
        top: $offset;
        right: $offset;
        bottom: $offset;
        left: $offset;
    }

    &.disabled {
        opacity: 0.1;
        pointer-events: none;
    }

    &.pulsate {
        background-color: $standardButtonColor;
        .svg-icon {
            fill: white;
        }
    }

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: $color;
    }
}
.navButton--prev {
    .svg-icon {
        transform: rotate(90deg);
    }
}
.navButton--next {
    .svg-icon {
        transform: rotate(-90deg);
    }
    &.nextIsGame {
        .svg-icon {
            $size: 1.2em;
            width: $size;
            height: $size;
            transform: rotate(0);
        }
        &.pulsate {
            .svg-icon {
                $size: 1em;
                width: $size;
                height: $size;
            }
        }
    }
}

@include media('<mobile') {
    .ActivityTab {
        height: $sizeSmall;
        border-radius: $sizeSmall;
    }
    .iconContainer {
        width: $sizeSmall;
        height: $sizeSmall;
    }
}
</style>

<template>
  <div class="AnimatedChekmark">
    <svg
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52">
      <circle
        class="circle"
        cx="26"
        cy="26"
        r="25"
        fill="none" />
      <path
        class="check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
</template>

<script>
export default {
    props: {},
    mounted() {
        //
    }
};
</script>

<style lang="scss" scoped>
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.AnimatedChekmark {
    display: block;
}

.checkmark {
    display: block;
    width: 100%;
    height: 100%;

    // width: 2em;
    // height: 2em;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: $white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $completionColor;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both, collapse 0.4s linear 2s forwards;
}

.circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $completionColor;
    fill: none;
    animation: stroke 0.6s $curve forwards;
}

.check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $completionColor;
    }
}

@keyframes collapse {
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}
</style>
